























import { Component, Vue } from 'vue-property-decorator'
import ResponsiveTemplate from '@/template/ResponsiveTemplate.vue'
import SearchConditionExpansion from '@/organisms/SearchConditionExpansion.vue'
import { findCompanies, findSeries } from '@/domain/service/findMaskingTape'
import AsyncComputed from 'vue-async-computed-decorator'
import MaskingTapeList from '@/organisms/MaskingTapeList.vue'
import { SearchCondition } from '@/domain/model/searchCondition'
import MtButton from '@/atoms/MtButton.vue'
import MtDialog from '@/molecules/MtDialog.vue'

@Component({
  components: {
    MtDialog,
    MtButton,
    MaskingTapeList,
    SearchConditionExpansion,
    ResponsiveTemplate
  }
})
export default class MaskingTapeSearch extends Vue {
  private searchCondition: SearchCondition = {
    itemName: '',
    companies: [],
    series: [],
    target: {
      items: true,
      myList: false,
      wishList: false
    }
  }

  private errorDialog = false

  @AsyncComputed({ default: [] })
  private async selectableCompanies() {
    return findCompanies()
  }

  @AsyncComputed({ default: [] })
  private async selectableSeries() {
    return findSeries()
  }

  private async search(searchable: boolean) {
    if (searchable) {
      await (this.$refs.maskingTapeList as MaskingTapeList).search()
    } else {
      this.errorDialog = true
    }
  }
}
