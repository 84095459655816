












import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MtChip extends Vue {
  @Prop({ required: true, type: String })
  private value!: string

  @Prop({ required: false, type: Boolean, default: false })
  private outlined!: boolean

  @Prop({ required: false, type: Boolean, default: false })
  private closeable!: boolean

  @Prop({ required: false, type: String, default: 'accent' })
  private color!: string

  @Emit()
  private close() {
    //
  }
}
