




















import { Component, PropSync, Vue } from 'vue-property-decorator'
import RequiredImageFile from '@/components/RequiredImageFile.vue'
import OptionalImageFile from '@/components/OptionalImageFile.vue'
import { PropType } from 'vue'

/**
 * 画像ファイルをアップロードするコンポーネント
 */
@Component({
  components: {
    RequiredImageFile,
    OptionalImageFile
  }
})
export default class ImageFileList extends Vue {
  @PropSync('images', {
    type: Array as PropType<string[]>,
    required: true
  })
  private syncedImages!: string[]

  @PropSync('error', {
    type: Boolean,
    required: true
  })
  private syncedError!: boolean
}
