





















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FooterButton extends Vue {
  @Prop({ type: String, required: true })
  private icon!: string

  @Prop({ type: String, required: true })
  private text!: string

  @Prop({ type: Boolean, required: true })
  private accent!: boolean

  @Emit()
  private click() {
    //
  }

  private get color() {
    return this.accent
      ? this.$vuetify.theme.themes.light.accent?.toString() ?? ''
      : ''
  }
}
