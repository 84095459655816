import { render, staticRenderFns } from "./OptionalImageFile.vue?vue&type=template&id=d7ce5f48&scoped=true&"
import script from "./OptionalImageFile.vue?vue&type=script&lang=ts&"
export * from "./OptionalImageFile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7ce5f48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VFileInput,VIcon,VImg})
