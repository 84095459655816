




























import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * 画像ファイルをアップロードするコンポーネント
 */
@Component
export default class ImageCarousel extends Vue {
  @Prop()
  private images!: string[]

  private model = 0
}
