









import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class MtCheckbox extends Vue {
  @PropSync('value', { required: true, type: Boolean })
  private syncedValue!: boolean

  @Prop({ required: true, type: String })
  private label!: string

  @Prop({ required: false, type: String, default: 'accent' })
  private color!: string
}
