














































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator'
import MtTextField from '@/atoms/MtTextField.vue'
import MtLabel from '@/atoms/MtLabel.vue'
import MtButton from '@/atoms/MtButton.vue'
import MtCheckbox from '@/atoms/MtCheckbox.vue'
import MtCombobox from '@/molecules/MtCombobox.vue'
import { SearchCondition } from '@/domain/model/searchCondition'

@Component({
  components: {
    MtCombobox,
    MtCheckbox,
    MtButton,
    MtLabel,
    MtTextField
  }
})
export default class SearchConditionExpansion extends Vue {
  @PropSync('value', { required: true })
  private syncedValue!: SearchCondition

  @Prop({ required: true })
  private selectableCompanies!: string[]

  @Prop({ required: true })
  private selectableSeries!: string[]

  private panel = []

  @Emit()
  private search(): boolean {
    const searchable = this.syncedValue.target.items || this.syncedValue.target.myList || this.syncedValue.target.wishList
    if (searchable) {
      this.panel = []
    }
    return searchable
  }
}
