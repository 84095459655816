



























import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseTemplate from '@/template/BaseTemplate.vue'

/**
 * 区切りがないひとつのコンテンツ用のテンプレート
 */
@Component({
  components: {
    BaseTemplate
  }
})
export default class ResponsiveTemplate extends Vue {
  @Prop({ type: Boolean, default: false })
  private noPadding!: boolean
}
