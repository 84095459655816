




























































import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator'
import { MaskingTape } from '@/entities/maskingTape'
import MtButton from '@/atoms/MtButton.vue'
import MtChip from '@/atoms/MtChip.vue'
import MtImage from '@/atoms/MtImage.vue'
@Component({
  components: { MtImage, MtChip, MtButton }
})
export default class MaskingTapePreview extends Vue {
  @Prop({ required: true })
  private maskingTape!: MaskingTape

  @PropSync('show', { required: true, type: Boolean })
  private syncedShow!: boolean

  private get maskingTapeImage(): string {
    return this.maskingTape.imageUrls[0]
  }

  /**
   * ダイアログの最大幅はmdサイズ以上であれば600pxとします。
   */
  private get maxWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 600
    }
    return 'none'
  }

  /**
   * プレビューで表示するチップスを取得します。
   */
  private get chips(): string[] {
    const chips = []

    // 会社名を追加
    if (this.maskingTape.company) {
      chips.push(this.maskingTape.company)
    }
    // シリーズを追加
    chips.push(...this.maskingTape.series)
    // サイズを追加
    if (this.maskingTape.widthMillimeter) {
      chips.push(`幅${this.maskingTape.widthMillimeter}mm`)
    }
    if (this.maskingTape.lengthMeter) {
      chips.push(`長さ${this.maskingTape.lengthMeter}m`)
    }
    // 色を追加
    chips.push(...this.maskingTape.colors)
    // 模様を追加
    chips.push(...this.maskingTape.patterns)
    return chips
  }

  @Emit()
  private clickDetail() {
    //
  }
}
