





















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

/**
 * ヘッダー、フッターを含む基底テンプレート
 */
@Component({
  components: {
    Header,
    Footer
  }
})
export default class BaseTemplate extends Vue {
  private get style() {
    return {
      background: this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes.dark.background
        : this.$vuetify.theme.themes.light.background
    }
  }
}
