



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MaskingTapeOptionalItem extends Vue {
  @Prop({ type: String, required: true })
  private label!: string

  /**
   * 各項目のテキストフィールドに対して付与するクラス属性を返却します。
   * 1. 小さい画面だけ`padding-top`を0に指定
   */
  private get columnsValueClass() {
    return {
      'pt-0': this.$vuetify.breakpoint.smAndDown
    }
  }

  /**
   * 各項目のラベルに対して付与するクラス属性を返却します。
   * 1. テキストカラーをテーマから取得
   * 2. 画面サイズに応じて右寄せ、左寄せを指定
   * （小さい画面では`v-col`の行が変わるので、左寄せにしたいため）
   * 3. 小さい画面だけ`padding-bottom`を0に指定
   */
  private get columnsLabelClass() {
    return {
      'text--text': true,
      'text-right': this.$vuetify.breakpoint.mdAndUp,
      'text-left': this.$vuetify.breakpoint.smAndDown,
      'pb-0': this.$vuetify.breakpoint.smAndDown
    }
  }
}
