





































import { Component, PropSync, Vue } from 'vue-property-decorator'
import { required } from '@/modules/validate'
import ImageFileList from '@/components/ImageFileList.vue'
import { PropType } from 'vue'

@Component({
  components: {
    ImageFileList
  }
})
export default class MaskingTapeRequiredSetting extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  @PropSync('images', {
    type: Array as PropType<string[]>,
    required: true
  })
  private syncedImages!: string[]

  @PropSync('name', {
    type: String,
    required: true
  })
  private syncedName!: string

  @PropSync('error', {
    type: Boolean,
    required: true
  })
  private syncedError!: boolean

  /**
   * indexedDBのMasTerデータベースインスタンス
   */
  private get maskingTapeTitleRules() {
    return [required()]
  }

  /**
   * 各項目のラベルに対して付与するクラス属性を返却します。
   * 1. テキストカラーをテーマから取得
   * 2. 画面サイズに応じて右寄せ、左寄せを指定
   * （小さい画面では`v-col`の行が変わるので、左寄せにしたいため）
   * 3. 小さい画面だけ`padding-bottom`を0に指定
   */
  private get columnsLabelClass() {
    return {
      'text--text': true,
      'text-right': this.$vuetify.breakpoint.mdAndUp,
      'text-left': this.$vuetify.breakpoint.smAndDown,
      'pb-0': this.$vuetify.breakpoint.smAndDown
    }
  }

  /**
   * 各項目のテキストフィールドに対して付与するクラス属性を返却します。
   * 1. 小さい画面だけ`padding-top`を0に指定
   */
  private get columnsValueClass() {
    return {
      'pt-0': this.$vuetify.breakpoint.smAndDown
    }
  }
}
