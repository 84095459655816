













































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import MtChip from '@/atoms/MtChip.vue'

@Component({
  components: {
    MtChip
  }
})
export default class MtCombobox extends Vue {
  // multipleがfalseの場合は1つを選択するため、modelも配列ではなくただの文字列となる
  @PropSync('model', { required: true })
  private syncedModel!: string | string[]

  @Prop({ default: [] })
  private items!: string[]

  /**
   * コンボボックスの検索文字列
   */
  private search: string | null = null

  /**
   * コンボボックスへ直接入力する際に、候補がない場合に表示するリストアイテムのクリックイベントハンドラです。
   * 選択肢へ入力した文字列を追加し、検索文字列を消します。
   */
  private appendItem() {
    if (!this.search) {
      return
    }
    // 複数選択の場合は、文字列配列へ追加する
    // 単一選択の場合は、文字列を代入
    if (Array.isArray(this.syncedModel)) {
      this.syncedModel.push(this.search)
    } else {
      this.syncedModel = this.search
    }
    // 検索文字列を削除
    this.search = null
  }

  /**
   * コンボボックスのchipsを削除ボタンを押下した場合は、その要素を消します。
   */
  private removeItem(item: string) {
    // 複数選択の場合は、文字列配列から削除する
    // 単一選択の場合は、文字列を削除
    if (Array.isArray(this.syncedModel)) {
      const index = this.syncedModel.indexOf(item)
      if (index >= 0) {
        this.syncedModel.splice(index, 1)
      }
    } else {
      this.syncedModel = ''
    }
  }
}
