

























import { Component, Vue } from 'vue-property-decorator'
import BaseTemplate from '@/template/BaseTemplate.vue'

// todo ResponsiveTemplate.vueと共通化
@Component({
  components: {
    BaseTemplate
  }
})
export default class DetailTemplate extends Vue {
}
