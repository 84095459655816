









































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

/**
 * 画像ファイルをアップロードするコンポーネント
 */
@Component
export default class RequiredImageFile extends Vue {
  @PropSync('image', { type: String as PropType<string | undefined> })
  private syncedImage?: string

  @PropSync('error', {
    type: Boolean,
    required: true
  })
  private syncedError!: boolean

  /**
   * 受け取ったFileインスタンスをBase64変換して返却します。
   */
  private readImageFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  private async filesSelected(files: File[]) {
    // TODO 選択した画像を解除する仕組みを作成すること
    if (files.length > 0) {
      // 画像を選択したため、エラー状態を消す
      this.syncedError = false

      this.syncedImage = await this.readImageFileAsync(files[0])
    }
  }
}
