





























import { Component, Vue } from 'vue-property-decorator'
import BaseTemplate from '@/template/BaseTemplate.vue'
import Header from '@/components/Header.vue'

@Component({
  components: {
    BaseTemplate,
    Header
  }
})
export default class Settings extends Vue {
  private readonly menus = [
    {
      text: '外観',
      name: 'Appearance'
    },
    {
      text: '利用規約',
      name: 'Terms'
    },
    {
      text: 'プライバシーポリシー',
      name: 'Privacy'
    }
  ]
}
