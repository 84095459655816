import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import MaskingTapeDetail from '@/views/MaskingTapeDetail.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/Privacy.vue'
import MaskingTapeSearch from '@/views/MaskingTapeSearch.vue'
import Settings from '@/views/Settings.vue'
import Appearance from '@/views/Appearance.vue'
import WishList from '@/views/WishList.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/wishlist',
    name: 'WishList',
    component: WishList
  },
  {
    path: '/mt/view/:mtId',
    name: 'MaskingTapeDetail',
    component: MaskingTapeDetail
  },
  {
    path: '/mt/search',
    name: 'Search',
    component: MaskingTapeSearch
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/settings/appearance',
    name: 'Appearance',
    component: Appearance
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({
    x: 0,
    y: 0
  })
})

export default router
