





























import { Component, Vue } from 'vue-property-decorator'
import ResponsiveTemplate from '@/template/ResponsiveTemplate.vue'
import Header from '@/components/Header.vue'
import { ThemeType } from '@/entities/setting'
import { MasTerDatabase } from '@/database/MasTerDatabase'

export type AppearanceType = {
  text: string;
  themeType: ThemeType;
}

@Component({
  components: {
    ResponsiveTemplate,
    Header
  }
})
export default class Appearance extends Vue {
  private db = MasTerDatabase.getInstance()

  private readonly appearanceTypes: AppearanceType[] = [
    {
      text: 'ライト',
      themeType: 'LIGHT'
    },
    {
      text: 'ダーク',
      themeType: 'DARK'
    },
    {
      text: 'システムと合わせる',
      themeType: 'SYSTEM'
    }
  ]

  private get themeType() {
    return this.db.themeType
  }

  private set themeType(themeType: ThemeType) {
    if (this.db.themeType === themeType) {
      return
    }
    this.db.changeTheme(themeType)
  }
}
