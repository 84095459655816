


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import BaseCombobox from '@/components/BaseCombobox.vue'
import { MasTerDatabase } from '@/database/MasTerDatabase'
import { AddedMaskingTape, ScrapingMaskingTape } from '@/entities/maskingTape'
import { v4 as uuid4 } from 'uuid'
import clone from 'clone'
import RequiredImageFile from '@/components/RequiredImageFile.vue'
import OptionalImageFile from '@/components/OptionalImageFile.vue'
import ImageFileList from '@/components/ImageFileList.vue'
import MaskingTapeOptionalSetting from '@/components/MaskingTapeOptionalSetting.vue'
import MaskingTapeRequiredSetting from '@/components/MaskingTapeRequiredSetting.vue'
import { findById } from '@/store/maskingTape'

@Component({
  components: {
    RequiredImageFile,
    OptionalImageFile,
    ImageFileList,
    MaskingTapeOptionalSetting,
    MaskingTapeRequiredSetting,
    BaseCombobox
  }
})
export default class MaskingTapeAdditionDialog extends Vue {
  $refs!: {
    form: HTMLFormElement
  }

  @Prop({ type: String })
  private selectedMaskingTapeId?: string

  private syncedDialog = false

  /**
   * indexedDBのMasTerデータベースインスタンス
   */
  private db = MasTerDatabase.getInstance()

  /**
   * この画面で追加するマスキングテープのデータ
   */
  private maskingTape = this.createDefaultMaskingTape()

  private maskingTapeImageError = false

  private createDefaultMaskingTape(): AddedMaskingTape {
    return {
      id: uuid4(),
      name: '',
      company: undefined,
      series: [],
      imageUrls: [],
      new: false,
      colors: [],
      patterns: [],
      listType: 'MyList'
    }
  }

  /**
   * マスキングテープのタイトルの仮名称を返却します。
   * 所持数+1の値を連番で最後に付与します。
   */
  private async createDefaultName(): Promise<string> {
    const myListLength = await this.db.myListTable.count()
    const wishListLength = await this.db.wishListTable.count()
    return `マスキングテープ${myListLength + wishListLength + 1}`
  }

  private async setDefaultMaskingTape() {
    this.maskingTape = this.createDefaultMaskingTape()
    this.maskingTape.name = await this.createDefaultName()
  }

  private clickAddMyListButton() {
    this.addMaskingTape(this.addMyList)
  }

  /**
   * マスキングテープ情報をMyListへ格納します。
   */
  private addMyList() {
    // TODO エラーハンドリングを追加すること
    this.maskingTape.listType = 'MyList'
    return this.db.myListTable.put(this.maskingTape)
  }

  private clickAddWishListButton() {
    this.addMaskingTape(this.addWishList)
  }

  /**
   * マスキングテープ情報をWishListへ格納します。
   */
  private addWishList() {
    // TODO エラーハンドリングを追加すること
    this.maskingTape.listType = 'WishList'
    return this.db.wishListTable.put(this.maskingTape)
  }

  private async addMaskingTape(addListFunc: () => Promise<number>) {
    if (!this.validate()) {
      return
    }
    await addListFunc()
    this.syncedDialog = false
    await this.setDefaultMaskingTape()
  }

  /**
   * 必須入力の項目と画像の有無を確認して、どちらもバリデーションチェックがOKな場合はtrueを返却します。
   */
  private validate() {
    // 画像が無い場合はコンポーネントのerror状態を変える
    this.maskingTapeImageError = this.maskingTape.imageUrls.length === 0
    return this.$refs.form.validate() && !this.maskingTapeImageError
  }

  private click() {
    this.syncedDialog = false
    this.$router.push({ name: 'Search' })
  }

  @Watch('syncedDialog')
  private watchDialog(dialog: boolean) {
    if (!dialog) {
      return
    }
    if (this.selectedMaskingTapeId) {
      const maskingTape = findById(this.selectedMaskingTapeId)
      if (maskingTape) {
        this.maskingTape = this.convertScrapingToAdded(maskingTape)
      }
    } else {
      this.setDefaultMaskingTape()
    }
  }

  private convertScrapingToAdded(scraping: ScrapingMaskingTape): AddedMaskingTape {
    return clone(scraping) as AddedMaskingTape
  }
}
