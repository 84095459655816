












import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { MaskingTape } from '@/entities/maskingTape'
import MtImage from '@/atoms/MtImage.vue'
import { getMaskingTapeImage } from '@/util/maskingTapeUtil'

@Component({
  components: {
    MtImage
  }
})
export default class MaskingTapeGridItem extends Vue {
  @Prop({ required: true })
  private maskingTape!: MaskingTape

  private get maskingTapeImage(): string {
    return getMaskingTapeImage(this.maskingTape)
  }

  @Emit()
  private click() {
    //
  }
}
