import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ja from 'vuetify/src/locale/ja'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#eadfca',
        secondary: '#aa998a',
        accent: '#9ec3bc',
        error: '#de503b',
        info: '#9ec3bc',
        success: '#9ec3bc',
        warning: '#e79d7a',
        background: '#eae8e3',
        text: '#293331'
      },
      dark: {
        primary: '#493a1d',
        secondary: '#907b69',
        accent: '#9ec3bc',
        error: '#de503b',
        info: '#9ec3bc',
        success: '#9ec3bc',
        warning: '#e79d7a',
        background: '#493a1d',
        text: '#d8e6e3'
      }
    },
    options: {
      customProperties: true
    }
  },
  lang: {
    locales: { ja },
    current: 'ja'
  }
})
