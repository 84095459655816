import { AddedMaskingTape } from '@/entities/maskingTape'
import { MasTerDatabase } from '@/database/MasTerDatabase'

const db = MasTerDatabase.getInstance()

export function getMyListMaskingTapes(): Promise<AddedMaskingTape[]> {
  return db.myListTable.toArray()
}

export function getWishListMaskingTapes(): Promise<AddedMaskingTape[]> {
  return db.wishListTable.toArray()
}
