






































import { Component, Vue } from 'vue-property-decorator'
import ResponsiveTemplate from '@/template/ResponsiveTemplate.vue'
import MarkdownViewer from '@/components/MarkdownViewer.vue'
import Header from '@/components/Header.vue'

@Component({
  components: {
    ResponsiveTemplate,
    Header,
    MarkdownViewer
  }
})
export default class Privacy extends Vue {
}
