




import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { MasTerDatabase } from '@/database/MasTerDatabase'

@Component
export default class App extends Vue {
  private db = MasTerDatabase.getInstance()

  @Watch('db.themeType', { immediate: true })
  private applyTheme() {
    // テーマを適用
    this.$vuetify.theme.dark = this.db.themeType === 'SYSTEM'
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
      : this.db.themeType === 'DARK'
  }
}
