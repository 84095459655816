



























import { Component, Vue } from 'vue-property-decorator'
import MaskingTapePreview from '@/molecules/MaskingTapePreview.vue'
import BaseTemplate from '@/template/BaseTemplate.vue'
import Header from '@/components/Header.vue'
import { MaskingTapes } from '@/entities/maskingTape'
import AsyncComputed from 'vue-async-computed-decorator'
import MaskingTapeList from '@/organisms/MaskingTapeList.vue'
import { SearchCondition } from '@/domain/model/searchCondition'
import { findMaskingTapes } from '@/domain/service/findMaskingTape'

@Component({
  components: {
    MaskingTapeList,
    MaskingTapePreview,
    BaseTemplate,
    Header
  }
})
export default class WishList extends Vue {
  private searchCondition: SearchCondition = {
    itemName: '',
    companies: [],
    series: [],
    target: {
      items: false,
      myList: false,
      wishList: true
    }
  }

  @AsyncComputed({ default: { data: [] } })
  private async additionMaskingTapeList(): Promise<MaskingTapes> {
    // TODO エラーハンドリングを追加すること
    // TODO 取得方法は見直すこと
    // (現状はマイリストに1件以上マステが追加されているかどうかを判定するためにしか使用していないため、limitは1としている)
    return await findMaskingTapes(this.searchCondition, 0, 1)
  }
}
