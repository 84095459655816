import maskingTapeData from '@/assets/json/filtered_data.json'

import { ScrapingMaskingTape } from '@/entities/maskingTape'

export const maskingTapeDataList: ScrapingMaskingTape[] = maskingTapeData

export const findById = (id: string): ScrapingMaskingTape | undefined => {
  const mtData = maskingTapeDataList.find(findByIdFunc(id))
  if (mtData) {
    return mtData
  }
  return undefined
}

// todo
const findByIdFunc = (id: string) => (maskingTape: ScrapingMaskingTape): boolean => {
  return maskingTape.id === id
}
