
































import { Component, Emit, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class DeleteDialog extends Vue {
  @PropSync('show', {
    required: true,
    type: Boolean
  })
  private syncedShow!: boolean

  /**
   * ダイアログの最大幅はmdサイズ以上であれば600pxとします。
   */
  private get maxWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 600
    }
    return 'none'
  }

  private close() {
    this.syncedShow = false
  }

  @Emit('delete')
  private onDelete() {
    return undefined
  }
}
