




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MtLabel extends Vue {
  @Prop({ required: true, type: String })
  private value!: string
}
