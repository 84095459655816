



























import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { MaskingTape } from '@/entities/maskingTape'
import MtImage from '@/atoms/MtImage.vue'
import { getMaskingTapeImage } from '@/util/maskingTapeUtil'

@Component({
  components: {
    MtImage
  }
})
export default class MaskingTapeListItem extends Vue {
  @Prop({ required: true })
  private maskingTape!: MaskingTape

  private get maskingTapeImage(): string {
    return getMaskingTapeImage(this.maskingTape)
  }

  private get companyAndSeries() {
    const list: string[] = []
    if (this.maskingTape.company) {
      list.push(this.maskingTape.company)
    }
    list.push(...this.maskingTape.series)
    return list.join(', ')
  }

  private get widthAndLength() {
    const list = []
    const width = this.maskingTape.widthMillimeter
    if (width) {
      list.push(`幅${width}mm`)
    }
    const length = this.maskingTape.lengthMeter
    if (length) {
      list.push(`長さ${length}m`)
    }
    return list.join(', ')
  }

  @Emit()
  private click() {
    //
  }
}
