



















import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import MtButton from '../atoms/MtButton.vue'

@Component({
  components: {
    MtButton
  }
})
export default class MtDialog extends Vue {
  @Prop({ required: false, type: String, default: '' })
  private title!: string

  @Prop({ required: false, type: String, default: '' })
  private text!: string

  @Prop({ required: false, type: String, default: '' })
  private buttonText!: string

  @Prop({ required: true, type: Boolean })
  private dialog!: boolean

  @Emit()
  private clickOutside() {
    //
  }

  @Emit()
  private clickButton() {
    //
  }
}
