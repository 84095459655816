import { MaskingTape } from '@/entities/maskingTape'

/**
 * サムネ用画像があるなら返却
 * 画像が一枚もない場合はマステの名前の画像を返却
 * 画像が一枚でもあれば一枚目を返却
 */
export function getMaskingTapeImage(maskingTape: MaskingTape): string {
  if (maskingTape.thumbnailUrl) {
    return maskingTape.thumbnailUrl
  }
  if (maskingTape.imageUrls.length === 0) {
    return `https://placehold.jp/cccccc/666666/150x150.png?text=${encodeURI(maskingTape.name)}`
  }
  return maskingTape.imageUrls[0]
}
