

































































































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import BaseCombobox from '@/components/BaseCombobox.vue'
import { MasTerDatabase } from '@/database/MasTerDatabase'
import { AddedMaskingTape, MaskingTape } from '@/entities/maskingTape'
import AsyncComputed from 'vue-async-computed-decorator'
import { PropType } from 'vue'
import MaskingTapeOptionalItem from '@/components/MaskingTapeOptionalItem.vue'
import { maskingTapeDataList } from '@/store/maskingTape'

@Component({
  components: {
    BaseCombobox,
    MaskingTapeOptionalItem
  }
})
export default class MaskingTapeOptionalSetting extends Vue {
  /**
   * indexedDBのMasTerデータベースインスタンス
   */
  private db = MasTerDatabase.getInstance()

  /**
   * この画面で追加するマスキングテープのデータ
   */
  @PropSync('maskingTape', {
    type: Object as PropType<AddedMaskingTape>,
    required: true
  })
  private syncedMaskingTape!: AddedMaskingTape

  /**
   *ユーザが所持しているマスキングテープを取得する
   */
  private get addedMaskingTapeList(): Promise<AddedMaskingTape[]> {
    // TODO エラーハンドリングを追加すること
    return (async() => {
      return ([] as AddedMaskingTape[])
        .concat(await this.db.myListTable.toArray())
        .concat(await this.db.wishListTable.toArray())
    })()
  }

  private get maskingTapeCompany(): string {
    return this.syncedMaskingTape.company ?? ''
  }

  private set maskingTapeCompany(company: string) {
    this.syncedMaskingTape.company = company === ''
      ? undefined
      : company
  }

  private get allMaskingTape(): Promise<MaskingTape[]> {
    return (async() => {
      return ([] as MaskingTape[])
        .concat(maskingTapeDataList)
        .concat(await this.addedMaskingTapeList)
    })()
  }

  /**
   * マスキングテープに設定されたシリーズ名の一覧を重複排除して返却します。
   */
  @AsyncComputed({
    default: [],
    watch: ['syncedMaskingTape.company']
  })
  private async getSeries() {
    const series = (await this.allMaskingTape)
      .filter(this.companyFilter)
      .map(maskingTape => maskingTape.series)
      .flat()
    // 重複排除して項目の入力候補に設定する
    return [...new Set(series)]
  }

  /**
   * スキングテープに設定されたメーカ名の一覧を重複排除して返却します。
   */
  @AsyncComputed({ default: [] })
  private async getMakerNameList(): Promise<string[]> {
    const companyList = (await this.allMaskingTape)
      .map(maskingTape => maskingTape.company)
      .filter((company): company is string => typeof company !== 'undefined')
    // 重複排除して項目の入力候補に設定する
    return [...new Set(companyList)]
  }

  /**
   * マスキングテープの色の一覧を重複排除して返却します。
   */
  @AsyncComputed({
    default: [],
    watch: ['syncedMaskingTape.company']
  })
  private async getColors() {
    const colors = (await this.allMaskingTape)
      .filter(this.companyFilter)
      .map(maskingTape => maskingTape.colors)
      .flat()
    // 重複排除して項目の入力候補に設定する
    return [...new Set(colors)]
  }

  /**
   * マスキングテープの模様の一覧を重複排除して返却します。
   */
  @AsyncComputed({
    default: [],
    watch: ['syncedMaskingTape.company']
  })
  private async getPatterns() {
    const colors = (await this.allMaskingTape)
      .filter(this.companyFilter)
      .map(maskingTape => maskingTape.patterns)
      .flat()
    // 重複排除して項目の入力候補に設定する
    return [...new Set(colors)]
  }

  private companyFilter(maskingTape: MaskingTape): boolean {
    if (this.syncedMaskingTape.company) {
      return this.syncedMaskingTape.company === maskingTape.company
    }
    return true
  }
}
