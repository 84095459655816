import Dexie from 'dexie'
import { AddedMaskingTape } from '@/entities/maskingTape'
import { DisplayType, Setting, ThemeType } from '@/entities/setting'

export class MasTerDatabase extends Dexie {
  private static readonly DB_NAME = 'MasTerDatabase'
  private static instance: MasTerDatabase

  private settingTable: Dexie.Table<Setting, number>
  public myListTable: Dexie.Table<AddedMaskingTape, number>
  public wishListTable: Dexie.Table<AddedMaskingTape, number>

  private _displayType: DisplayType = 'LIST'
  private _themeType: ThemeType = 'LIGHT'

  private constructor() {
    super(MasTerDatabase.DB_NAME)

    this.version(1).stores({
      settings: '++',
      myListTable: 'id',
      wishListTable: 'id'
    })

    this.settingTable = this.table('settings')
    this.myListTable = this.table('myListTable')
    this.wishListTable = this.table('wishListTable')

    // db作成時に初期データを投入
    this.on('populate', () => {
      this.settingTable.add({
        displayType: 'LIST',
        themeType: 'LIGHT'
      })
    })

    // 設定の読み込み
    this.on('ready', async() => {
      const setting = await this.settingTable.get(1)
      if (setting) {
        this._displayType = setting.displayType
        this._themeType = setting.themeType
      }
    })
  }

  public get displayType(): DisplayType {
    return this._displayType
  }

  public get themeType(): ThemeType {
    return this._themeType
  }

  public static getInstance(): MasTerDatabase {
    if (!MasTerDatabase.instance) {
      MasTerDatabase.instance = new MasTerDatabase()
    }
    return MasTerDatabase.instance
  }

  public async changeTheme(themeType: ThemeType): Promise<void> {
    this._themeType = themeType
    await this.settingTable.update(1, { themeType })
  }

  public changeToList(): void {
    this._displayType = 'LIST'
    this.settingTable.update(1, { displayType: 'LIST' })
  }

  public changeToGrid(): void {
    this._displayType = 'GRID'
    this.settingTable.update(1, { displayType: 'GRID' })
  }
}
