

































































































































import { Component, Vue } from 'vue-property-decorator'
import DetailTemplate from '@/template/DetailTemplate.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import { findById } from '@/store/maskingTape'
import { MaskingTape } from '@/entities/maskingTape'
import { MasTerDatabase } from '@/database/MasTerDatabase'
import Header from '@/components/Header.vue'
import ImageCarousel from '@/components/ImageCarousel.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import MaskingTapeStorageLocationChip from '@/components/MaskingTapeStorageLocationChip.vue'
import MaskingTapeAdditionDialog from '@/components/MaskingTapeAdditionDialog.vue'

@Component({
  components: {
    MaskingTapeStorageLocationChip,
    DeleteDialog,
    DetailTemplate,
    Header,
    ResponsiveImage,
    MaskingTapeAdditionDialog,
    ImageCarousel
  }
})
export default class MaskingTapeDetail extends Vue {
  private db = MasTerDatabase.getInstance()
  private maskingTape: MaskingTape | null = null

  private deleteDialog = false

  private get title() {
    return this.maskingTape
      ? this.maskingTape.name
      : ''
  }

  private get isAddedMaskingTape() {
    if (this.maskingTape) {
      return 'listType' in this.maskingTape
    }
    return false
  }

  private get infoList(): { key: string, value: string }[] {
    const infoList: { key: string, value: string }[] = []

    if (this.maskingTape?.company) {
      infoList.push({
        key: 'メーカー',
        value: this.maskingTape.company
      })
    }
    if (this.maskingTape?.series && this.maskingTape.series.length > 0) {
      const series = this.maskingTape.series.join(', ')
      infoList.push({
        key: 'シリーズ',
        value: series
      })
    }
    if (this.maskingTape?.widthMillimeter) {
      infoList.push({
        key: '幅',
        value: this.maskingTape.widthMillimeter + ' mm'
      })
    }
    if (this.maskingTape?.lengthMeter) {
      infoList.push({
        key: '長さ',
        value: this.maskingTape.lengthMeter + ' m'
      })
    }
    if (this.maskingTape?.colors && this.maskingTape?.colors.length > 0) {
      const colors = this.maskingTape.colors.join(', ')
      infoList.push({
        key: '色',
        value: colors
      })
    }
    if (this.maskingTape?.patterns && this.maskingTape?.patterns.length > 0) {
      const patterns = this.maskingTape.patterns.join(', ')
      infoList.push({
        key: '模様',
        value: patterns
      })
    }
    if (this.maskingTape?.priceExcludingTax) {
      infoList.push({
        key: 'メーカ価格',
        value: '¥ ' + this.maskingTape.priceExcludingTax + ' (税別)'
      })
    }
    if (this.maskingTape?.productNumber) {
      infoList.push({
        key: '製造番号',
        value: this.maskingTape.productNumber
      })
    }
    if (this.maskingTape?.jan) {
      infoList.push({
        key: 'JANコード',
        value: this.maskingTape.jan
      })
    }
    if (this.maskingTape?.url) {
      infoList.push({
        key: 'URL',
        value: this.maskingTape.url
      })
    }

    return infoList
  }

  /**
   * 各項目のテキストフィールドに対して付与するクラス属性を返却します。
   * 1. 小さい画面だけ`padding-top`を0に指定
   */
  private get columnsValueClass() {
    return {
      'pt-0': this.$vuetify.breakpoint.smAndDown
    }
  }

  private get columnsLabelClass() {
    return {
      'text--text': true,
      'text-right': this.$vuetify.breakpoint.mdAndUp,
      'text-left': this.$vuetify.breakpoint.smAndDown,
      'pb-0': this.$vuetify.breakpoint.smAndDown
    }
  }

  /**
   * ダイアログの最大幅はmdサイズ以上であれば600pxとします。
   */
  private get maxWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 600
    }
    return 'none'
  }

  private async deleteMaskingTape() {
    if (!this.maskingTape) {
      return
    }
    await this.db.myListTable.where({ id: this.maskingTape.id }).delete()
    await this.db.wishListTable.where({ id: this.maskingTape.id }).delete()
    await this.$router.push({ name: 'Home' })
  }

  private async created() {
    const maskingTapeId = this.$route.params.mtId
    this.maskingTape = await this.findMaskingTape(maskingTapeId) || null
  }

  /**
   * 引数idでマスキングテープを検索します。
   * マイリストから検索、見つからない場合は商品一覧から検索します。
   * どちらにもない場合は`Promise<undefined>`を返します。
   */
  private async findMaskingTape(id: string): Promise<MaskingTape | undefined> {
    // MyListからマステを検索
    const myListMaskingTape = await this.db.myListTable.get({ id })
    if (myListMaskingTape) {
      return myListMaskingTape
    }

    // WishListからマステを検索
    const wishListMaskingTape = await this.db.wishListTable.get({ id })
    if (wishListMaskingTape) {
      return wishListMaskingTape
    }

    // 見つからない場合は商品一覧からマステを検索
    return findById(id)
  }
}
