











































import { Component, Prop, Vue } from 'vue-property-decorator'
import MaskingTapeListItem from '@/molecules/MaskingTapeListItem.vue'
import { MaskingTape } from '@/entities/maskingTape'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import { SearchCondition } from '@/domain/model/searchCondition'
import { findDisplayType } from '@/domain/service/findSetting'
import { findMaskingTapes } from '@/domain/service/findMaskingTape'
import MaskingTapeGridItem from '@/molecules/MaskingTapeGridItem.vue'
import { DisplayType } from '@/entities/setting'
import MaskingTapePreview from '@/molecules/MaskingTapePreview.vue'

@Component({
  components: {
    MaskingTapePreview,
    MaskingTapeGridItem,
    MaskingTapeListItem,
    InfiniteLoading
  }
})
export default class MaskingTapeList extends Vue {
  @Prop({ required: true })
  private searchCondition!: SearchCondition

  private maskingTapes: MaskingTape[] = []

  private selectedMaskingTape: MaskingTape = {
    id: '',
    productNumber: '',
    name: '',
    series: [],
    priceExcludingTax: 0,
    widthMillimeter: 0,
    lengthMeter: 0,
    jan: '',
    url: '',
    imageUrls: [],
    thumbnailUrl: '',
    new: false,
    company: '',
    material: '',
    country: '',
    colors: [],
    patterns: []
  }

  private isSelected = false

  private pagingContext = {
    offset: MaskingTapeList.DEFAULT_OFFSET,
    limit: MaskingTapeList.DEFAULT_LIMIT
  }

  private static DEFAULT_OFFSET = 0
  private static DEFAULT_LIMIT = 30

  private async next() {
    const addMaskingTapes = await findMaskingTapes(this.searchCondition, this.pagingContext.offset, this.pagingContext.limit)
    this.maskingTapes.push(...addMaskingTapes.data)
    this.pagingContext.offset = this.pagingContext.offset + this.pagingContext.limit

    if (this.maskingTapes.length === 0) {
      this.stateChanger.reset()
      this.stateChanger.complete()
    } else {
      this.stateChanger.loaded()
      if (this.maskingTapes.length === addMaskingTapes.context.totalCount) {
        this.stateChanger.complete()
      }
    }
  }

  async search(): Promise<void> {
    this.stateChanger.reset()
    this.maskingTapes = []
    this.pagingContext.offset = MaskingTapeList.DEFAULT_OFFSET
    this.pagingContext.limit = MaskingTapeList.DEFAULT_LIMIT
    // infinite-loading から自動的に next が呼ばれる
  }

  private get stateChanger(): StateChanger {
    return (this.$refs.infiniteLoading as InfiniteLoading).stateChanger as StateChanger
  }

  private get displayType(): DisplayType {
    return findDisplayType()
  }

  private selectMaskingTape(maskingTape: MaskingTape) {
    this.selectedMaskingTape = maskingTape
    this.isSelected = true
  }

  private detail() {
    this.$router.push({ name: 'MaskingTapeDetail', params: { mtId: this.selectedMaskingTape.id } })
  }
}
