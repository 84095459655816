

























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

/**
 * 画像ファイルをアップロードするコンポーネント
 */
@Component
export default class OptionalImageFile extends Vue {
  @PropSync('images', {
    type: Array as PropType<string[]>,
    required: true
  })
  private syncedImages!: string[]

  @Prop({
    type: Number,
    required: true
  })
  private index!: number

  private get image() {
    return this.syncedImages[this.index + 1]
  }

  private set image(value: string) {
    this.syncedImages.splice(this.index + 1, 1, value)
  }

  /**
   * 受け取ったFileインスタンスをBase64変換して返却します。
   */
  private readImageFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  private async filesSelected(files: File[]) {
    // TODO 選択した画像を解除する仕組みを作成すること
    if (files.length > 0) {
      this.image = await this.readImageFileAsync(files[0])
    }
  }
}
