var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{attrs:{"hide-no-data":!_vm.search,"items":_vm.items,"multiple":_vm.multiple,"search-input":_vm.search,"chips":"","clearable":"","color":"accent","hide-details":"","hide-selected":"","item-color":"accent"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',{on:{"click":_vm.appendItem}},[_c('v-chip',{attrs:{"color":"accent","outlined":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")]),_c('span',{staticClass:"pl-2"},[_vm._v("を追加")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","close-icon":"mdi-close","color":"accent","outlined":""},on:{"click:close":function($event){return _vm.removeItem(item)}}},'v-chip',attr,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item))])])]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"accent","outlined":""}},[_vm._v(" "+_vm._s(item)+" ")])]}}]),model:{value:(_vm.syncedModel),callback:function ($$v) {_vm.syncedModel=$$v},expression:"syncedModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }