












import { Component, Vue } from 'vue-property-decorator'
import { MasTerDatabase } from '@/database/MasTerDatabase'

@Component
export default class DisplaySwitchButton extends Vue {
  /**
   * indexedDBのMasTerデータベースインスタンス
   */
  private db = MasTerDatabase.getInstance()

  private get displaySwitchIcon() {
    return this.db.displayType === 'LIST'
      ? 'mdi-view-grid'
      : 'mdi-view-list'
  }

  private change() {
    if (this.db.displayType === 'LIST') {
      this.db.changeToGrid()
    } else {
      this.db.changeToList()
    }
  }
}
