

























































import { Component, Vue, Watch } from 'vue-property-decorator'
import FooterButton from '@/components/FooterButton.vue'
import MaskingTapeAdditionDialog from '@/components/MaskingTapeAdditionDialog.vue'

@Component({
  components: {
    FooterButton,
    MaskingTapeAdditionDialog
  }
})
export default class Footer extends Vue {
  // 遷移すると初期化されてしまうため、クラス変数として扱う
  private static globalActiveIndex = -1
  // クラス変数はリアクティブな処理の対象とならないため、インスタンス変数と同期させる
  private activeIndex = Footer.globalActiveIndex

  private mounted() {
    // locationからactiveにするボタンを選択する
    const locationList = ['Home', 'WishList', '', 'Search', 'Settings']
    const locationIndex = locationList
      .findIndex(location => location === this.$route.name)
    if (locationIndex !== -1) {
      this.activeIndex = locationIndex
    }
  }

  private isAccent(location: string) {
    return location === this.$route.name
  }

  private transPage(locationName: string) {
    if (this.$route.name === locationName) {
      return
    }
    this.$router.push({ name: locationName })
  }

  private click(index: number, location: string) {
    this.activeIndex = index
    this.transPage(location)
  }

  @Watch('activeIndex')
  private changeActiveIndex(activeIndex: number) {
    Footer.globalActiveIndex = activeIndex
  }
}
