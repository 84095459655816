



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { AddedMaskingTape } from '@/entities/maskingTape'

/**
 * マスキングテープの保管場所を表示するコンポーネント
 * 保管しているかどうかは製造番号が一致するかどうかで判定し、「My List」、「Wish List」のいずれかを表示します。
 */
@Component
export default class MaskingTapeStorageLocationChip extends Vue {
  @Prop({ required: true })
  private maskingTape!: AddedMaskingTape

  private get isWishListMaskingTape() {
    return this.maskingTape.listType === 'WishList'
  }

  private get isMyMaskingTape() {
    return this.maskingTape.listType === 'MyList'
  }
}
