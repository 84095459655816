




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import DisplaySwitchButton from '@/components/DisplaySwitchButton.vue'

@Component({
  components: {
    DisplaySwitchButton
  }
})
export default class Header extends Vue {
  @Prop({ type: Boolean })
  private logo!: boolean

  @Prop({ type: Boolean })
  private back!: boolean

  @Prop({ type: Boolean })
  private displaySwitch!: boolean

  @Prop({ type: String })
  private centerText!: string
}
