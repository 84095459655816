import { getScrapingMaskingTapes } from '@/infrastructure/repository/scrapingMtRepository'
import { MaskingTape, MaskingTapes } from '@/entities/maskingTape'
import { getMyListMaskingTapes, getWishListMaskingTapes } from '@/infrastructure/repository/addedMtRepository'
import { SearchCondition } from '@/domain/model/searchCondition'

export async function findMaskingTapes(searchCondition: SearchCondition, offset: number, limit: number): Promise<MaskingTapes> {
  let maskingTapes: MaskingTape[] = []
  if (searchCondition) {
    if (searchCondition.target.items) {
      maskingTapes.push(...await getScrapingMaskingTapes())
    }
    if (searchCondition.target.myList) {
      maskingTapes.push(...await getMyListMaskingTapes())
    }
    if (searchCondition.target.wishList) {
      maskingTapes.push(...await getWishListMaskingTapes())
    }

    if (searchCondition.itemName && searchCondition.itemName !== '') {
      maskingTapes = maskingTapes.filter(maskingTape => maskingTape.name.includes(searchCondition.itemName))
    }
    if (searchCondition.companies && searchCondition.companies.length > 0) {
      maskingTapes = maskingTapes.filter(maskingTape => {
        return searchCondition.companies.some(company => company === maskingTape.company)
      })
    }
    if (searchCondition.series && searchCondition.series.length > 0) {
      maskingTapes = maskingTapes.filter(maskingTape => {
        return searchCondition.series.some(searchSeries => {
          return maskingTape.series.some(mtSeries => mtSeries.includes(searchSeries))
        })
      })
    }
  } else {
    maskingTapes = (await findAllMaskingTapes()).data
  }

  const data = maskingTapes.slice(offset, offset + limit)

  return {
    context: {
      offset: offset,
      limit: limit,
      totalCount: maskingTapes.length
    },
    data: data
  }
}

export async function findAllMaskingTapes(): Promise<MaskingTapes> {
  const data = ([] as MaskingTape[])
    .concat(await getScrapingMaskingTapes())
    .concat(await getMyListMaskingTapes())
    .concat(await getWishListMaskingTapes())
  return {
    context: {
      offset: 0,
      limit: data.length,
      totalCount: data.length
    },
    data: data
  }
}

export async function findSeries(): Promise<string[]> {
  const series = (await findAllMaskingTapes()).data
    .map(maskingTape => maskingTape.series)
    .flat()
  return [...new Set(series)]
}

export async function findCompanies(): Promise<string[]> {
  const companyList = (await findAllMaskingTapes()).data
    .map(maskingTape => maskingTape.company)
    .filter((company): company is string => typeof company !== 'undefined')
  return [...new Set(companyList)]
}
