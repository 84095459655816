










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ResponsiveImage extends Vue {
  @Prop({ required: true, type: String })
  private src!: string
}
